<template>
  <div class="login-form">
    <!-- Login form -->
    <form @submit.prevent="inputtype === 'Email' ? emailForm() : mobileForm()">
      <div class="form-group">
        <div class="login-form-resendLink" v-if="resendLink">
          <p class="login-form-resendLink-text">
            {{ resendLink }}
            <span @click="handleResendLink">{{ resendText }}</span>
          </p>
        </div>
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        <div v-if="inputtype === 'Email'">
          <div class="form-control">
            <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
              <label class="login-label">{{ $t("email id") }}</label>
              <span class="required">*</span>
            </div>
            <div class="input-container">
              <input
                id="userEmail"
                :class="localDisplayLang === 'ara' ? 'py-l' : 'py-r'"
                type="email"
                ref="email"
                v-model="userEmail"
                autocomplete="false"
                placeholder="Email ID/ Mobile number"
              />
              <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
                <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
              </span>
            </div>
          </div>
          <div class="form-control">
            <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
              <label class="login-label">{{ $t("password") }}</label>
              <span class="required">*</span>
            </div>
            <div class="input-container" dir="ltr">
              <input
                id="userPassword"
                type="password"
                v-model="userPassword"
                autocomplete="false"
                :placeholder="`${passwordPlaceholder}`"
                @keydown.space.prevent
              />
              <span class="eye">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-control">
            <div class="label-container">
              <label class="login-label">{{ $t("mobilenumber") }}</label>
              <span class="required">*</span>
            </div>
            <div class="input-container">
              <input id="userMobileno" type="text" ref="mobileno" v-model="userMobileno" placeholder="Mobile Number" />
              <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
                <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
              </span>
            </div>
          </div>
          <div class="form-control">
            <div
              v-if="appConfig.featureEnabled.isMobileOtpLoginEnabled"
              class="radio"
              :class="localDisplayLang === 'ara' ? 'rtl_radio' : ''"
            >
              <input type="radio" id="password" value="password" v-model="loginType" />
              <label for="password">
                {{ $t("password") }}
                <span></span>
              </label>
              <input type="radio" id="otp" value="otp" v-model="loginType" />
              <label for="otp">
                OTP
                <span></span>
              </label>
            </div>
          </div>
          <div class="form-control" v-if="loginType === 'otp'">
            <div class="label-container">
              <label class="login-label">{{ $t("enter otp") }}</label>
              <span class="required">*</span>
            </div>
            <div id="pininputs" class="pin-inputs" dir="ltr">
              <input
                v-for="(item, index) in 6"
                :key="index"
                class="pin"
                type="text"
                v-model="userPin[item - 1]"
                @input="grabPin"
                maxlength="1"
              />
            </div>
            <div class="form-control">
              <span class="login-form-notreceived">{{ $t("not recieved otp") }}</span
              >&nbsp; <span class="login-form-resend" @click="resend(true)">{{ $t("resend") }}</span
              >&nbsp;&nbsp;
              <span class="login-form-timer" v-show="showCounter">(00:{{ counter }})</span>
            </div>
          </div>
          <div class="form-control" v-if="loginType === 'password'">
            <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
              <label class="login-label">{{ $t("password") }}</label>
              <span class="required">*</span>
            </div>
            <div class="input-container" dir="ltr">
              <input
                id="userPassword"
                type="password"
                v-model="userPassword"
                autocomplete="false"
                :placeholder="`${passwordPlaceholder}`"
                @keydown.space.prevent
              />
              <span class="eye">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
        </div>

        <!-- Captcha For login -->
        <div v-if="isCaptchaEnabled && captchaImage">
          <label class="login-label">{{ $t("Please enter the numbers you see in the image") }}</label>
          <div
            v-if="captchaImage"
            :style="{
              backgroundImage: 'url(' + captchaImage + ')',
              'background-repeat': 'no-repeat',
              'background-color': 'white',
              width: '150px',
            }"
            class="captcha-image"
          ></div>
        </div>

        <div v-if="isCaptchaEnabled">
          <button @click="getAnotherCaptcha" class="button-link" type="button">
            {{ $t("Click to refresh if you can not read") }}
          </button>
        </div>

        <div v-if="isCaptchaEnabled">
          <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
            <!-- <label class="login-label">{{ $t("The result") }}</label>
            <span class="required">*</span> -->
          </div>
          <div class="input-container" dir="ltr">
            <input
              id="loginCaptcha"
              type="text"
              v-model="loginCaptcha"
              :placeholder="`${this.$t('The result')}`"
              @keydown.space.prevent
            />
          </div>
        </div>
      </div>
      <span
        class="forgot-password-span"
        @click="forgotPassword"
        v-if="inputtype === 'Email' || loginType === 'password'"
        >{{ $t("forgot password") }}</span
      >

      <div v-if="loginType === 'otp'">
        <p class="otp_error_text">{{ $t("otp text") }}</p>
      </div>

      <div class="login-button">
        <button
          class="button-primary login"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          type="submit"
        >
         <span class="btn-txt-color"> {{ $t("login") }} </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";

import { _providerId } from "@/provider-config.js";

import FirebaseActions from "@/mixins/FirebaseActions.js";

import snackbar from "node-snackbar";
import { showSnackBar } from '../../../../utilities';
export default {
  props: {
    input: {
      type: String,
    },
    inputtype: {
      type: String,
    },
  },
  data() {
    return {
      userEmail: "",
      userMobileno: "",
      passwordPlaceholder: "",
      userPassword: null,
      formError: null,
      enableLoader: false,
      resendLink: null,
      resendText: null,
      userPin: [],
      profilePin: "",
      loginType: "password",
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      showTimer: false,
      showCounter: false,
      counter: 29,
      providerUniqueId: _providerId,
      localDisplayLang: null,

      isCaptchaEnabled: false,
      loginCaptcha: null,
      captchaImage: null,
      captchaReferenceId: null,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "gettoken", "subscriberid", "getRtl", "country", "profileid"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
    subscriberid(val) {
      if (val) {
        this.$emit("closePopup");
      }
    },
    loginType(val) {
      if (val === "otp") {
        this.setupPin();
        this.logingAPIforOTP();
        // this.resend();
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    // Checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },

  mounted() {
    this.passwordPlaceholder = this.$t("password");

    this.setupForm();

    if (this.inputtype === "Mobile" && this.loginType === "otp") {
      this.setupPin();
    }

    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("social-captch-event", (data) => {
      let info = {
        data,
      };

      info.formType = "socialCaptcha";
      this.$emit("change", info);
    });

    eventBus.$on("login-response", (response) => {
      let GA = {};
      if (response.reason) {
        if (response.errorcode === 6110) {
          this.resendLink = response.reason;
          this.resendText = this.inputtype === "Email" ? "RESEND LINK" : "REGISTER MOBILE";
		}else if (response.errorcode === 6114){
			this.formError = this.$t("limit-reached-6114");
		} else if (response.errorcode === 6121){
			this.formError = this.$t("limit-reached-6121");
		}else {
          console.log("response.reason text  ==============", response.reason);
          this.formError = this.$t(response.reason);
          this.userPin = [];

          if (response.errorcode !== 7303) {
            this.isCaptchaEnabled = false;
            this.loginCaptcha = "";
            this.captchaImage = null;
            this.captchaReferenceId = null;
          }
        }

        //constructing google analytics details.
        GA.login_mode = this.inputtype === "Email" ? "Email" : "Mobile";
        GA.status = "Failed";
        GA.error_reason = response.reason;
      } else if (response.successtype === "CAPTCHA") {
        this.isCaptchaEnabled = true;

        this.captchaImage = response && response.success ? response.success.payload : "";
        this.captchaReferenceId = response && response.success ? response.success.referenceid : "";

        console.log("THIS IS THE LOGIN TYPE", response);
      } else {
        console.log("data.response.login success", response);

        //constructing google analytics details.
        GA.login_mode = this.inputtype === "Email" ? "Email" : "Mobile";
        GA.status = "Success";
        // let message = "Login Successfull"
        // this.snackbarMessage(message);
        localStorage.setItem("sessionToken", response.success);
        // eventBus.$emit("subscriberDetails");

        if(this.appConfig.featureEnabled.isMultipleProfileEnabled) {
          eventBus.$on("subscriberDetails-set-success", (data, response) => {
            if(data,response.data.email == null){
            eventBus.$emit("emailMandatoryPopup", true);
              this.showProfilePopup = true;
              return;
            }
            if(!data.autologin) {
              this.listProfiles().then((profileResponse) => {
                if (profileResponse.data.reason) {
                  this.formError = this.$t(profileResponse.data.reason);
                } else {
                  profileResponse.data.data.forEach((profile, index) => {
                    if (profile.profileid === this.profileid) {
                      localStorage.setItem("currentProfileId", profile.profileid);
                      localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
    
                      this.setProfileid(profile.profileid);
                    }
                  });

                  if (!sessionStorage.getItem("setPlanDeeplinkPath")?.toLowerCase().includes("voucher") &&
                  profileResponse.data.totalcount > 1) {
                   
                      let currentLanguage = this.getCurrentLanguageUrlBase();
                      if(sessionStorage.getItem("setPlanDeeplinkPath")?.includes("viewPlanAndCoupon")){
                        console.log("setPlanDeeplinkPath-login")
                      }else {
                        this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
                      }
                  } else {

                    if(profileResponse && profileResponse.data && profileResponse.data.data) {
                      // let profileData = profileResponse.data.data;
                      // localStorage.setItem("currentProfileId", profileData[0].profileid);
                      // localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileData[0]));
      
                      // this.setProfileid(profileData[0].profileid);
                      // eventBus.$emit("deeplink-voucher", true);
							        //sessionStorage.removeItem("setPlanDeeplinkPath")
                      profileResponse.data.data.forEach((profile, index) => {
                        if (profile.profileid === this.profileid) {
                          localStorage.setItem("currentProfileId", profile.profileid);
                          localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));

                          this.setProfileid(profile.profileid);
                          if(sessionStorage.getItem("setPlanDeeplinkPath")?.toLowerCase().includes("voucher")){
                            eventBus.$emit("deeplink-voucher", true);
                          }
                        }
                      });
                    }

                  }
                }
              });
            }
          });
        }


        const appConfig = this.appConfig;
        let convertingMobileViewControllerToBoolean;

        if (appConfig && appConfig.mobileViewController) {
          convertingMobileViewControllerToBoolean = JSON.parse(this.appConfig.mobileViewController.toLowerCase());
        }

        //console.log("app config------------------====================",typeof(convertingMobileViewControllerToBoolean))

        if (convertingMobileViewControllerToBoolean) {
          console.log("inside if=================");
          this.checkMobileWeb();
        }
      }

      const browser = this.getPlatformType();

      GA.platform = this.getPlatform();

      GA.platform_type = browser.browser;

      console.log("THE LOGIN GA", GA);

      //logging signup google analytics event.
      this.loginEvent(GA);
    });

    eventBus.$on("resend-response", (response) => {
      this.resendLink = null;
      if (response.reason) {
        this.formError = response.reason;
      } else {
        if (this.showTimer) {
          this.showCounter = true;
          let timer = setInterval(() => {
            this.counter--;
            if (this.counter < 0) {
              clearInterval(timer);
              this.counter = 29;
              this.showCounter = false;
              this.showTimer = false;
            }
          }, 1000);
        }
      }
    });

    eventBus.$on("forgotpassword-response", (response) => {
      if (response.reason) {
        if (response.errorcode === 6110) {
          this.resendLink = response.reason;
          this.resendText = this.inputtype === "Email" ? "RESEND LINK" : "REGISTER MOBILE";
        } else {
          this.formError = response.reason;
        }
      } else {
        eventBus.$emit("auth-close");
        this.triggerSnackbarEvent();
        this.triggerForgotPasswordEvent();
      }
    });
  },

  methods: {
    ...mapMutations(["setUserInfo", "setToken", "setProfileid"]),
    ...mapActions(["actGetLogin", "actGetSubscriber", "getCaptcha", "login", "listProfiles"]),

    getAnotherCaptcha() {
      this.getCaptchaImage();
    },

    logingAPIforOTP() {

      let payload = {
        params: {
          devicetype: "PC",
          deviceos: this.getDeviceOS(),
          mobileno: this.userMobileno,
          country: this.country ? this.country.CountryCode : "",
        },
      };

      this.login(payload.params).then(data => {
        console.log("THE OTP HAS BEEN SENT --> ", data);
      }).catch(err => {
        console.log("Error in sending otp", err);
      })
    },

    handleResendLink() {

      if(this.appConfig.featureEnabled.isCaptchaEnabled) {
        let template = {
          input: this.inputtype === 'Email' ? "email" : 'mobile' ,
          formType: "verifyResendCaptcha",
          email : this.userEmail ? this.userEmail : "",
          mobileno: this.userMobileno ? this.userMobileno : ""
        };
        
        this.$emit("change", template);
      } else {
        if(this.inputtype === 'Email') {
          this.resend()
        } else {
          this.registerMobile()
        }
      }

    },

    getCaptchaImage() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }

          this.captchaImage = data.payload;
          this.captchaReferenceId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },

    checkMobileWeb() {
      console.log("check mobile web in login============");
      let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

      if (!isMobile) {
        const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

        if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
          isMobile = true;
        }
      }
      if (isMobile && localStorage.getItem("isMobile") == "true") {
        let payload = {
          state: true,
          message: "Login successfull",
          color: "green",
        };
        eventBus.$emit("snackBarMessage", payload);
        eventBus.$emit("triggerInitView", payload);
        let subscriberPayload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", subscriberPayload);
      }
    },

    setupForm() {
      if (this.input && this.inputtype === "Email") {
        this.$refs.email.disabled = true;
        this.userEmail = this.input;
        let input = document.getElementById("userEmail");
        input.style.backgroundColor = "#5C5C5D";
        input.style.opacity = "0.3";
      }

      if (this.input && this.inputtype === "Mobile") {
        this.$refs.mobileno.disabled = true;
        this.userMobileno = this.input;
        let input = document.getElementById("userMobileno");
        input.style.backgroundColor = "#5C5C5D";
        input.style.opacity = "0.3";
        // this.resend();
      }
    },

    snackbarMessage(message) {
      console.log("snackbar message called");
      // snackbar.show({
      //   text: message,
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t(message));
    },

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    changeFrom() {
      let info = {
        formType: "lookup",
      };
      this.$emit("change", info);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += parseInt(element);
        });
        this.profilePin = pin;
        // console.log("profilePin", this.profilePin);
      }
    },

    resend(state) {


      if(this.appConfig.featureEnabled.isCaptchaEnabled) {
        let template = {
          input: this.inputtype === 'Email' ? "email" : 'mobile' ,
          formType: "verifyResendCaptcha",
          mobileno: this.userMobileno ? this.userMobileno : ""
        };
          
        this.$emit("change", template);
      } else {

        this.showTimer = state;
  
        let payload = {};
        if (this.inputtype === "Mobile") {
          payload.mobileno = this.userMobileno;
        } else {
          payload.email = this.userEmail;
        }
        eventBus.$emit("resend-link", payload);
      }

    },

    registerMobile() {
      let template = {
        input: this.userMobileno,
        formType: "mobile",
        isResend: true
      };
      this.$emit("change", template);
    },

    emailForm() {
      if (
        !this.validateEmail(this.userEmail) ||
        !this.validatePassword(this.userPassword) ||
        !this.validateCountryCode()
      )
        return;

			let coupon_campaign = localStorage.getItem('campaign-coupon');
      let payload = {
        app: "SELF",
        params: {
          email: this.userEmail.trim(),
          password: this.userPassword.trim(),
          devicetype: "PC",
          deviceos: this.getDeviceOS(),
          country: this.country.CountryCode,
					couponcode: coupon_campaign
        },
      };

      if (this.isCaptchaEnabled) {
        if (this.loginCaptcha) {
          payload.params.captcha = JSON.stringify({
            text: this.loginCaptcha,
            referenceid: this.captchaReferenceId,
          });
        } else if (!this.loginCaptcha) {
          this.formError = this.$t("Please enter the given captcha");
          return;
        }
      }

      eventBus.$emit("subscriberLogin", payload);
    },

    mobileForm() {
			let coupon_campaign = localStorage.getItem('campaign-coupon');
      let payload = {
        params: {
          devicetype: "PC",
          deviceos: this.getDeviceOS(),
          mobileno: this.userMobileno,
          country: this.country ? this.country.CountryCode : "",
					couponcode: coupon_campaign

        },
      };

      if (this.isCaptchaEnabled) {
        if (this.loginCaptcha) {
          payload.params.captcha = JSON.stringify({
            text: this.loginCaptcha,
            referenceid: this.captchaReferenceId,
          });
        } else {
          this.formError = this.$t("Please enter Captcha");
          return;
        }
      }

      if (this.loginType === "otp") {
        if (!this.validateOtp()) return;
        payload.params.otp = this.profilePin;
      }

      if (this.loginType === "password") {
        if (!this.validatePassword(this.userPassword) || !this.validateCountryCode()) return;
        payload.params.password = this.userPassword;
      }

      eventBus.$emit("subscriberLogin", payload);
    },

    forgotPassword() {
      if (this.appConfig.featureEnabled.isCaptchaEnabled) {
        this.triggerForgotPasswordEvent();
      } else {
        let payload = {};

        if (this.inputtype === "Email") {
          payload.email = this.userEmail;
        } else {
          payload.mobileno = this.userMobileno;
        }
        eventBus.$emit("forgotPasswordRequest", payload);
      }
    },

    triggerForgotPasswordEvent() {
      let payload = {
        input: this.inputtype === "Email" ? this.userEmail : this.userMobileno,
        inputtype: this.inputtype,
      };
      eventBus.$emit("showForgotPassword", payload);
    },

    triggerSnackbarEvent() {
      let payload = {
        state: true,
        message:
          this.inputtype === "Email" ? "OTP has been sent to your mail id" : "OTP has been sent your mobile number",
        color: "#ff9600",
      };
      eventBus.$emit("snackBarMessage", payload);
    },

    validateOtp() {
      this.formError = null;
      if (!this.profilePin) {
        this.formError = this.$t("Please Enter OTP");
        return false;
      }
      return true;
    },

    // validatePassword() {
    //   this.formError = null;
    //   if (!this.userPassword) {
    //     this.formError = "Please enter password";
    //     return false;
    //   }
    //   return true;
    // }
  },

  beforeDestroy() {
    eventBus.$off("login-response");
    eventBus.$off("resend-response");
    eventBus.$off("forgotpassword-response");
  },
  

  mixins: [Utility, googleAnalytics, cleverTap, FirebaseActions],

  beforeDestroy() {
    eventBus.$off("subscriberDetails-set-success");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.captcha {
  &-image {
    margin: 10px 0;
    height: 50px;
  }
}

.login-form {
  .otp_error_text {
    color: #ffffff;
    opacity: 0.5;
    font-family: $font-regular;
    line-height: 14px;
    margin-bottom: 20px;
    font-size: 10px;
    font-style: italic;
  }
  &-resendLink {
    &-text {
      font-size: 12px;
      font-family: $font-regular;
      color: red;
      span {
        text-decoration: underline;
        font-weight: bold;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
  &-notreceived {
    opacity: 0.5;
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
  &-resend {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    cursor: pointer;
  }
  &-timer {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
}
.forgot-password-span {
  opacity: 0.5;
  color: $clr-light-gd3;
  font-family: $font-regular;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2rem;
  cursor: pointer;
}
.login-button {
  margin-top: 0.6rem;
  .login {
    width: 100%;
  }
}

.py-l {
  padding-left: 3.4rem !important;
}

.py-r {
  padding-right: 3.4rem !important;
}

.radio input[type="radio"] {
  display: none;
  /*removes original button*/
}

.radio label {
  position: relative;
  padding-right: 1.2rem;
  color: $clr-popup-label;
}

.radio input[type="radio"] + label:before {
  /*styles outer circle*/
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 11px;
  border: 2px solid $clr-popup-label;
  background-color: transparent;
}

.radio input[type="radio"]:checked + label {
  color: #ffffff;
}

.radio input[type="radio"]:checked + label:before {
  border: 2px solid $btn-clr;
}

.radio input[type="radio"]:checked + label > span {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: block;
  background-color: $btn-clr;
}

.rtl_radio input[type="radio"] {
  display: none;
  /*removes original button*/
}

.rtl_radio label {
  position: relative;
  padding-right: 1.2rem;
  color: $clr-popup-label;
}

.rtl_radio input[type="radio"] + label:before {
  /*styles outer circle*/
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 13px;
  height: 13px;
  border-radius: 11px;
  border: 2px solid $clr-popup-label;
  background-color: transparent;
}

.rtl_radio input[type="radio"]:checked + label {
  color: #ffffff;
}

.rtl_radio input[type="radio"]:checked + label:before {
  border: 2px solid $btn-clr;
}

.rtl_radio input[type="radio"]:checked + label > span {
  border-radius: 11px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 5px;
  right: 29px;
  display: block;
  background-color: $btn-clr;
}

.input-container {
  position: relative;
  .rtl-change {
    color: #ffffff;
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .rtl-eye {
    position: absolute;
    top: 35%;
    left: 3%;
    opacity: 0.5;
    img {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
	.btn-txt {
		text-transform: uppercase;
		background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
</style>
